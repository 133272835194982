class AccordianItem {
  constructor(item, container) {
    this.item = item;
    this.container = container;
    this.content = item.querySelector('.accordian-content');
    this.isOpen = false;
    this.toggleButton = item.querySelector('button');

    this.toggleButton.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick() {
    if (!this.isOpen) {
      this.container.closeAccordianItems();
      this.open();
      return;
    }

    if (this.container.allowClose) {
      this.close();
    }
  };

  open() {
    // hide closed icon
    if (this.container.hideToggle) {
      this.toggleButton.classList.add('d-none');
    }
    // show content
    this.content.classList.remove('d-none');
    this.isOpen = true;
  }

  close() {
    // show closed icon
    this.toggleButton.classList.remove('d-none');
    // hide content
    this.content.classList.add('d-none');
    this.isOpen = false;
  }

  destroy() {
    this.toggleButton.removeEventListener('click', this.handleClick.bind(this));
  }
}

export default AccordianItem;
