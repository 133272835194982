/**
 * Returns the lazy loading options for the specified component name.
 * @param {string} componentName
 * @returns {Promise<import('./LazyLoadingOptions').LazyLoadingOptions>}
 * @throws {Error} Throws an error if the lazy loading options for the specified component name are not found.
 */
export async function lazyLoadingOptionsFactory(componentName) {
  switch (componentName) {
    case 'product':
      return (await import('../product/LazyLoadProductOptions')).default;
    case 'service':
      return (await import('../job/LazyLoadServicesOptions')).default;
    default:
      throw new Error(`Lazy loading options for ${componentName} not found`);
  }
}
