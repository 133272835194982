import mediator from '../services/shared/mediator';
import RefreshableComponent from './RefreshableComponent';

/**
 * The Trigger component provides functionality to trigger publishing
 * mediator messages dynamically on the page.
 */
class Trigger extends RefreshableComponent {
  /**
   * Get the name of the class.
   * @returns {string} The name of the class.
   */
  static name() { return 'Trigger'; }

  /**
   * Create a Trigger.
   * @param {Object} options - The options for the trigger.
   * @param {string} options.channel - The channel to publish the message on (required).
   * @param {string} options.triggerElementId - If passed in, a child element with this ID will be
   * bound to the trigger event, otherwise the HTML element associated with this component will be used.
   * @param {string} options.triggerElementClass - If passed in, a child element with this class will be
   * bound to the trigger event, otherwise the HTML element associated with this component will be used.
   * @param {string} options.triggerEvent - The name of the event to listen for (options). Default is 'click'.
   * @param {HTMLElement} el - The HTML element associated with the trigger.
   * @description This component will trigger a custom event when the button is clicked.
   */
  constructor({ channel, triggerElementId, triggerElementClass, triggerEvent = 'click', ...args }, el) {
    super();
    if (!channel) {
      throw new Error('Trigger requires a channel.');
    }

    const idSelector = triggerElementId ? `#${triggerElementId}` : null;
    const classSelector = triggerElementClass ? `.${triggerElementClass}` : null;
    const querySelector = idSelector || classSelector;
    this.triggerElement = querySelector ? el.querySelector(querySelector) : el;
    if (!this.triggerElement) {
      throw new Error(`Trigger button with ID ${triggerElementId} not found.`);
    }

    this.messageArguments = args;
    this.channel = channel;
    this.triggerEvent = triggerEvent;
    this.el = el;
  }

  /**
   * Initialize the trigger.
   */
  init() {
    console.info('~~~ Trigger Command ~~~');

    const triggerEventHandler = this.publish.bind(this);
    this.triggerElement.addEventListener(this.triggerEvent, triggerEventHandler);
  }

  /**
   * publish the message to the given channel.
   * @param {Event} event - The event that triggered the function (optional).
   */
  publish(event) {
    mediator.publish(this.channel, this.el, event, this.messageArguments);
  }

  /**
   * Clean up event listeners.
   */
  destroy() {
    this.triggerElement.removeEventListener(this.triggerEvent, this.publish.bind(this));
  }
}

export default Trigger;
