import PractitionerSignUpBase from './PractitionerSignUpBase';

class PractitionerSignUp extends PractitionerSignUpBase {
  static name() { return 'PractitionerSignUp'; }

  constructor(props, el) {
    super(props, el);
    this.props = props;
    this.el = el;
    this.$el = $(el);

    this.objects = {
      $form: this.$el.find('[data-form]'),
      $emailField: this.$el.find('#email'),
      $emailConfirmField: this.$el.find('#emailConfirm'),
      $passwordField: this.$el.find('#password'),
      $passwordConfirmField: this.$el.find('#passwordConfirm'),
      $firstNameField: this.$el.find('#firstName'),
      $lastNameField: this.$el.find('#lastName'),
      $phoneField: this.$el.find('#phone'),
      $promoField: this.$el.find('#promo'),
      $honeypotField: this.$el.find('#zipcode'),
      $agreeField: this.$el.find('#agree'),
      $step1Header: this.$el.find('[data-step-1-header]'),
      $step1Background: this.$el.find('[data-step-1-background]'),
      $step1: this.$el.find('[data-step-1]'),
      $step1Submit: this.$el.find('[data-step-1]').find('[data-submit]'),
      $step1ErrorMessages: this.$el.find('[data-step-1]').find('[data-error-messages]'),
      $step1ErrorMessageTemplate: this.$el.find('[data-step-1]').find('[data-error-messages-template]'),
      $step2Header: this.$el.find('[data-step-2-header]'),
      $step2Background: this.$el.find('[data-step-2-background]'),
      $step2: this.$el.find('[data-step-2]'),
      $step2Submit: this.$el.find('[data-step-2]').find('[data-submit]'),
      $step2ErrorMessages: this.$el.find('[data-step-2]').find('[data-error-messages]'),
      $step2ErrorMessageTemplate: this.$el.find('[data-step-2]').find('[data-error-messages-template]'),
      $stepProgress: this.$el.find('[data-step-progress]'),
    };

    this.validateTermsAndConditions = () => {
      const { $agreeField } = this.objects;

      if (!$agreeField.is(':checked')) {
        this.$el.find('#globalValidation').removeClass('d-none');

        return false;
      }

      return true;
    };

    this.checkStep1CustomValidationsAsync = async () => {
      const emailValid = await this.validateEmailAsync();
      const passwordValid = this.validatePassword();
      const promoCodeValid = await this.validatePromoCodeAsync();
      return emailValid && passwordValid && promoCodeValid;
    };

    this.checkStep2CustomValidationsAsync = async () => this.validateTermsAndConditions()
      && this.checkStep1CustomValidationsAsync();

    this.handleStep1SubmitAsync = e => this.handleStepSubmitAsync(
      e,
      this.objects.$step1Submit,
      this.submitStep1,
      this.checkStep1CustomValidationsAsync,
      this.sendRegistrationPage1ToHubSpot,
    );

    this.handleStep2SubmitAsync = e => this.handleStepSubmitAsync(
      e,
      this.objects.$step2Submit,
      this.submitStep2,
      this.checkStep2CustomValidationsAsync,
      this.sendRegistrationPage2ToHubSpot,
    );

    this.sendRegistrationPage1ToHubSpot = () => {
      const {
        $firstNameField,
        $lastNameField,
        $emailField,
        $phoneField,
      } = this.objects;

      const hscookie = this.getCookie('hubspotutk');
      const dt = new Date().setUTCHours(0, 0, 0, 0);

      const formData = {
        fields: [
          { name: 'email', value: $emailField.val() },
          { name: 'firstname', value: $firstNameField.val() },
          { name: 'lastname', value: $lastNameField.val() },
          { name: 'provider_sign_up_phone_number', value: $phoneField.val() },
          { name: 'date_signed_up', value: dt },
          { name: 'type_of_contact', value: 'Did not accept T&C\'s' },
          { name: 'profile_pic_added', value: 'No' },
          { name: 'professional_statement_added', value: 'No' },
        ],
        context: {
          hutk: hscookie,
          pageUri: 'www.sofiahealth.com/signup_practitioner',
          pageName: 'Signup',
        },
      };

      this.logHS(formData); // Log it for the record
    };

    this.sendRegistrationPage2ToHubSpot = () => {
      const { $emailField, $agreeField } = this.objects;
      const hscookie = this.getCookie('hubspotutk');
      const dt = new Date().setUTCHours(0, 0, 0, 0);

      const formData = {
        fields: [
          { name: 'email', value: $emailField.val() },
          { name: 'agree_to_terms_and_conditions_website', value: $agreeField.val() },
          { name: 'type_of_contact', value: 'Accepted T & C\'s' },
          { name: 'subscription_start_date', value: dt },
        ],
        context: {
          hutk: hscookie,
          pageUri: 'www.sofiahealth.com/signup_practitioner_tc',
          pageName: 'Signup_TC',
        },
      };

      this.logHS(formData); // Log it for the record
    };

    this.submitStep1 = () => this.submitStep(
      this.objects.$step1Submit.data('submit-url'),
      this.objects.$step1Submit,
      this.objects.$step1ErrorMessages,
      this.objects.$step1ErrorMessageTemplate,
      this.showStep2,
    );

    this.submitStep2 = () => this.submitStep(
      this.objects.$step2Submit.data('submit-url'),
      this.objects.$step2Submit,
      this.objects.$step2ErrorMessages,
      this.objects.$step2ErrorMessageTemplate,
    );

    this.showStep1 = () => {
      const {
        $step1Header,
        $step1Background,
        $step1,
        $step1ErrorMessages,
        $step1Submit,
        $step2Header,
        $step2Background,
        $step2,
        $step2ErrorMessages,
        $step2Submit,
        $stepProgress,
      } = this.objects;

      $step1Submit.prop('disabled', false);
      $step1Header.removeClass('d-none');
      $step1Background.removeClass('d-none');
      $step1.removeClass('d-none');
      $step1ErrorMessages.html('');
      $step2Submit.prop('disabled', true);
      $step2ErrorMessages.html('');
      $step2Header.addClass('d-none');
      $step2Background.addClass('d-none');
      $step2.addClass('d-none');
      $stepProgress.removeClass('active');
      $($stepProgress.get(0)).addClass('active');
      this.hideSpinner();
    };

    this.showStep2 = () => {
      const {
        $step1Header,
        $step1Background,
        $step1,
        $step1ErrorMessages,
        $step1Submit,
        $step2Header,
        $step2Background,
        $step2,
        $step2ErrorMessages,
        $step2Submit,
        $stepProgress,
      } = this.objects;

      $step1Submit.prop('disabled', true);
      $step1Header.addClass('d-none');
      $step1Background.addClass('d-none');
      $step1.addClass('d-none');
      $step1ErrorMessages.html('');
      $step2Submit.prop('disabled', false);
      $step2ErrorMessages.html('');
      $step2Header.removeClass('d-none');
      $step2Background.removeClass('d-none');
      $step2.removeClass('d-none');
      $stepProgress.removeClass('active');
      $($stepProgress.get(1)).addClass('active');
      this.hideSpinner();
    };
  }

  init() {
    console.info('~~~ PractitionerSignUp ~~~');
    const {
      $emailField,
      $emailConfirmField,
      $passwordField,
      $passwordConfirmField,
      $promoField,
      $agreeField,
      $step1Submit,
      $step2Submit,
    } = this.objects;

    $emailField.on('invalid', { missingErrorMessage: 'Please enter your email.' }, this.handleInvalid);
    $emailField.on('input', { resetAnotherField: $emailConfirmField }, this.handleInputReset);

    $emailConfirmField.on('invalid', { missingErrorMessage: 'Please confirm your email.' }, this.handleInvalid);
    $emailConfirmField.on('input', this.handleInputReset);

    $passwordField.on('invalid', { missingErrorMessage: 'Please enter your password.' }, this.handleInvalid);
    $passwordField.on('input', { resetAnotherField: $passwordConfirmField }, this.handleInputReset);

    $passwordConfirmField.on('invalid', { missingErrorMessage: 'Please confirm your password.' }, this.handleInvalid);
    $passwordConfirmField.on('input', { resetAnotherField: $passwordField }, this.handleInputReset);

    $promoField.on('invalid', this.handleInvalid);
    $promoField.on('input', this.handleInputReset);

    $agreeField.on('invalid', this.handleInvalid);
    $agreeField.on('input', this.handleInputReset);

    $step1Submit.on('click', this.handleStep1SubmitAsync);
    $step2Submit.on('click', this.handleStep2SubmitAsync);

    this.showStep1();
  }
}

export default PractitionerSignUp;
