/**
 * Mediator pattern implementation for pub/sub functionality.
 */
const mediator = (() => {
  const channels = {};

  /**
   * Subscribes a callback function to a specific channel.
   * @param {string} channel - The channel to subscribe to.
   * @param {Object} context - The context in which the callback should be executed.
   * @param {Function} callback - The callback function to execute when the channel is published.
   * @returns {Object} - Returns an object with the subscription details and an unsubscribe method.
   */
  const subscribe = (channel, context, callback) => {
    if (!channels[channel]) channels[channel] = [];
    const subscription = { context, callback };
    channels[channel].push({ context, callback });
    return {
      ...subscription,
      unsubscribe: () => {
        channels[channel] = channels[channel].filter(subscriber => subscriber !== subscription);
      },
    };
  };

  /**
   * Publishes an event to a specific channel, triggering all subscribed callbacks.
   * @param {string} channel - The channel to publish to.
   * @param {...*} args - The arguments to pass to the callback functions.
   * @returns {boolean} - Returns false if the channel has no subscribers.
   */
  const publish = (channel, ...args) => {
    if (!channels[channel]) return false;
    channels[channel].forEach((subscriber) => {
      subscriber.callback.apply(subscriber.context, args);
    });
  };

  return {
    publish,
    subscribe,
  };
})();

export default mediator;
