import {
  renderManualSessionInput,
  renderScheduleInput,
  setDisplayState,
  validateSessionTimes,
} from '../services/index';
import { buildPostFormData } from '../utils';
import mediator from '../services/shared/mediator';
import RefreshableComponent from './RefreshableComponent';

class GroupSessionForm extends RefreshableComponent {
  static name() { return 'GroupSessionForm'; }

  constructor({ daysOfWeek, minDate, validationApiUrl }, el) {
    super();
    this.el = el;
    this.daysOfWeek = JSON.parse(el.querySelector(`#${daysOfWeek}`).textContent);
    this.minDate = minDate;
    this.validateGroupSessionApi = buildPostFormData(validationApiUrl);
    this.abortController = new AbortController();
  }

  init() {
    console.info('~~~ Group Session Form ~~~');

    this.recurringStartDateEl = document.getElementById('recurring_start_date');
    this.recurringEndDateEl = document.getElementById('recurring_end_date');
    this.groupSessionsEnabledEl = document.getElementById('group_sessions_enabled');
    this.idGroupSizeEl = document.getElementById('id_group_size');
    this.groupSizeNoLimitEl = document.getElementById('group_size_no_limit');
    this.manualEntrySessionFormEl = document.getElementById('manual-entry-session-form');
    this.recurringSessionFormEl = document.getElementById('recurring-session-form');
    this.recurringSessionInputsEl = document.getElementById('recurring-session-inputs');
    this.sessionTypeEl = document.getElementById('session_type');
    this.manualSessionContainerEl = document.getElementById('manual-session-container');
    this.manualAddBtnEl = document.getElementById('manual-add-btn');

    this.setupForm();
  }

  markFormAsUnsaved() {
    mediator.publish('unsavedChanges:add', this);
  }

  setupForm() {
    this.idGroupSizeEl.disabled = !this.groupSessionsEnabledEl.checked;
    this.groupSizeNoLimitEl.addEventListener('change', () => {
      const input = this.idGroupSizeEl;
      if (this.groupSizeNoLimitEl.checked) {
        input.value = '';
        input.disabled = true;
      }
      else {
        input.value = '2';
        input.disabled = false;
      }
    }, { signal: this.abortController.signal });

    this.groupSessionsEnabledEl.addEventListener('change', (e) => {
      const el = this.manualEntrySessionFormEl;
      const inputs = el.querySelectorAll('input');
      if (e.target.checked) {
        inputs.forEach(input => input.required = true);
        const noLimit = this.groupSizeNoLimitEl.checked;
        if (!noLimit) {
          this.idGroupSizeEl.value = '2';
          this.idGroupSizeEl.disabled = false;
        }
        else {
          this.idGroupSizeEl.disabled = true;
        }
      }
      else {
        inputs.forEach(input => input.required = false);
        this.idGroupSizeEl.disabled = true;
      }
    }, { signal: this.abortController.signal });

    const sessionTypeIdMap = {
      1: {
        formEl: this.recurringSessionFormEl,
        inputsEl: this.recurringSessionInputsEl,
      },
      2: {
        formEl: this.manualEntrySessionFormEl,
        inputsEl: this.manualEntrySessionFormEl,
      },
    };

    this.sessionTypeEl.addEventListener('change', () => {
      const { value } = this.sessionTypeEl;
      const sessionType = sessionTypeIdMap[value];
      // togle display
      sessionType.formEl.classList.remove('d-none');
      sessionType.formEl.classList.add('d-block');
      // set all inputs to required
      const inputs = sessionType.inputsEl.querySelectorAll('input:not(.copy-menu input)');

      inputs.forEach(input => input.required = true);
      // hide other divs
      Object.keys(sessionTypeIdMap)
        .filter(k => k !== value)
        .forEach((k) => {
          const { formEl, inputsEl } = sessionTypeIdMap[k];
          formEl.classList.remove('d-block');
          formEl.classList.add('d-none');

          Array.from(inputsEl.querySelectorAll('input')).forEach(input => input.required = false);
        });
    }, { signal: this.abortController.signal });

    this.manualSessionContainerEl.addEventListener('click', (e) => {
      if (e.target.closest('.manual-remove-btn')) {
        e.preventDefault();
        const el = e.target.closest('div');
        el.nextElementSibling.remove();
        el.remove();
        this.markFormAsUnsaved();
      }
    }, { signal: this.abortController.signal });

    this.manualAddBtnEl.addEventListener('click', (e) => {
      e.preventDefault();
      const html = renderManualSessionInput(this.minDate);
      this.manualSessionContainerEl.insertAdjacentHTML('beforeend', html);
      this.markFormAsUnsaved();
    }, { signal: this.abortController.signal });

    // loop through each day of the week and set up event handlers for recurring session week inputs
    this.daysOfWeek.forEach(({ id: dayId }) => {
      const maxFields = 10;
      const wrapper = document.getElementById(dayId);
      let x = wrapper.querySelectorAll('#schedule').length;

      setDisplayState(x, wrapper, dayId);

      wrapper.addEventListener('click', (e) => {
        if (e.target.closest(`#${dayId}_remove`)) {
          e.preventDefault();
          x -= 1;
          const el = e.target.closest('div').parentElement;
          el.nextElementSibling.remove();
          el.remove();
          setDisplayState(x, wrapper, dayId);
          this.markFormAsUnsaved();
        }
      }, { signal: this.abortController.signal });

      document.getElementById(`${dayId}_add`).querySelector('a').addEventListener('click', (e) => {
        e.preventDefault();
        if (x < maxFields) {
          x += 1;
          wrapper.querySelectorAll('#unavailable').forEach(el => el.remove());
          document.getElementById(`${dayId}_copy`).querySelector('.dropbtn').style.cursor = 'pointer';
          document.getElementById(`${dayId}_copy`).querySelector('.dropdown-content').style.display = '';
          document.getElementById(`${dayId}_copy`).querySelector('.dropbtn .fa.fa-copy').style.color = 'black';
          const scheduleHtml = renderScheduleInput(dayId);
          const schedule = document.createElement('div');
          schedule.innerHTML = scheduleHtml;
          wrapper.appendChild(schedule);
        }
        this.markFormAsUnsaved();
      }, { signal: this.abortController.signal });

      // copy group session times to selected days
      document.getElementById(`${dayId}_copy`).querySelector('.copy_button').addEventListener('click', (e) => {
        e.preventDefault();
        const inputs = document.querySelectorAll(`#${dayId}_copy input[type=checkbox]:checked`); // get days that are checked
        inputs.forEach((input) => {
          const dayValue = input.value;
          const day = document.getElementById(dayValue); // element to copy to
          const schedule = document.getElementById(dayId); // element to copy from

          day.innerHTML = ''; // remove existing contents
          day.innerHTML = schedule.innerHTML; // replace with contents from existing day

          // copy time input values
          const newTimes = day.querySelectorAll('input[type="time"]');
          schedule.querySelectorAll('input[type="time"]').forEach((timeInput, i) => {
            newTimes[i].value = timeInput.value;
            newTimes[i].name = `${dayValue}_start`;
            newTimes[i].setAttribute('aria-label', `${dayValue}_start`);
          });
          day.querySelectorAll('.day-remove-btn').forEach((btn) => {
            btn.id = `${dayValue}_remove`;
          });
        });
        this.markFormAsUnsaved();
      });
    }, { signal: this.abortController.signal });

    // validate session times on change of data
    document.getElementById('manual-entry-session-form').addEventListener('change', (e) => {
      e.preventDefault();
      validateSessionTimes(this.validateGroupSessionApi);
    }, { signal: this.abortController.signal });

    document.getElementById('recurring-session-form').addEventListener('change', (e) => {
      e.preventDefault();
      validateSessionTimes(this.validateGroupSessionApi);
    }, { signal: this.abortController.signal });

    document.getElementById('sessionLengthForm').addEventListener('change', (e) => {
      e.preventDefault();
      const groupSessionsEnabled = document.getElementById('group_sessions_enabled').checked;
      if (groupSessionsEnabled) {
        validateSessionTimes(this.validateGroupSessionApi);
      }
    }, { signal: this.abortController.signal });

    // call on page load to display any errors for existing sessions
    validateSessionTimes(this.validateGroupSessionApi, { scroll: false });
  }

  destroy() {
    this.abortController.abort();
  }
}

export default GroupSessionForm;
