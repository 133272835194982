import { buildPostFormData } from '../utils';
import mediator from '../services/shared/mediator';
import { createErrorBanner, formOptionsFactory } from '../services';
import RefreshableComponent from './RefreshableComponent';

/**
 * Form component class.
 * @description Handles form submission, validation, and warning for unsaved changes.
 */
class Form extends RefreshableComponent {
  static name() { return 'Form'; }

  #formOptions = null;

  /**
   * Creates a new Form instance.
   * @param {*} options
   * @param {HTMLFormElement} el
   */
  constructor({ errorBanner, formName, redirectTo, ...props }, el) {
    super();
    this.el = el; // form element
    formOptionsFactory(formName).then((FormOptions) => {
      this.#formOptions = new FormOptions(props);
    });
    this.errorBannerEl = document.querySelector(`#${errorBanner}`);
    if (!this.errorBannerEl) {
      this.errorBannerEl = createErrorBanner();
      this.el.prepend(this.errorBannerEl);
    }
    this.redirectTo = redirectTo;
    this.postForm = buildPostFormData(this.el.action);
    this.submitButton = this.el.querySelector('button[type="submit"]');
    this.abortController = new AbortController();
    this.subscriptions = [];
  }

  init() {
    console.info('~~~ Form ~~~');

    /**
     * Warns against unsaved changes.
     * @param {Event} e
     */
    const warnAgainstUnsavedChanges = (e) => {
      e.preventDefault();
      // legacy browser support
      return true;
    };

    // set up unsaved changes warning
    const setUnsavedChangesHandler = () => {
      // don't add the event listener more than once
      if (this.unsavedChangesWarningSet) return;
      // Data has been changed so add the beforeunload event listener
      window.addEventListener('beforeunload', warnAgainstUnsavedChanges, { signal: this.abortController.signal });
      this.unsavedChangesWarningSet = true;
    };

    const sub = mediator.subscribe('unsavedChanges:add', this, setUnsavedChangesHandler);
    this.subscriptions.push(sub);
    this.el.addEventListener('input', setUnsavedChangesHandler, { signal: this.abortController.signal });

    this.el.addEventListener('submit', (e) => {
      // Data has been saved so cleanup the beforeunload event listener
      window.removeEventListener('beforeunload', warnAgainstUnsavedChanges);
      this.unsavedChangesWarningSet = false;
      this.#submit.apply(this, [e]);
    });

    // avoid changing number input on scroll
    this.el.addEventListener('wheel', () => {
      if (document.activeElement instanceof HTMLInputElement && document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    }, { passive: true, signal: this.abortController.signal });
  }

  /**
   * Handles the form submission event.
   * @param {SubmitEvent} event
   */
  async #submit(event) {
    event.preventDefault();

    if (this.submitButton) this.submitButton.disabled = true;

    // run pre-submit actions
    const preSubmitResult = await this.#formOptions.preSubmit(this.el);
    if (!preSubmitResult) {
      this.errorBannerEl.style.display = 'block';
      this.#setErrorState(event);
      return;
    }
    // check form validity
    const isOK = this.#formOptions.isValid(this.el);
    this.el.classList.add('was-validated');
    if (isOK) {
      console.log('form validated', isOK);

      // show preloader animation
      mediator.publish('preloader:show');

      this.postForm(new FormData(this.el))
        .then(async (data) => {
          console.log('Form submitted', data);

          // run post-submit actions
          const postSubmitResult = await this.#formOptions.postSubmit(this.el, data);

          if (this.submitButton) this.submitButton.disabled = false;
          if (!postSubmitResult) {
            this.#setErrorState(event);
            return;
          }

          if (this.redirectTo) {
            window.location.href = this.redirectTo;
          }
          else {
            window.location.reload();
          }
        })
        .catch((error) => {
          // hide preloader animation
          mediator.publish('preloader:hide');
          if (this.submitButton) this.submitButton.disabled = false;

          // run post-submit error actions
          this.#formOptions.postError(this.el, error);
        });
    }
    else {
      if (this.submitButton) this.submitButton.disabled = false;
      this.#setErrorState(event);
    }
  }

  #setErrorState(event) {
    this.el.reportValidity();
    this.errorBannerEl.style.display = 'block';
    window.scrollTo(0, 0);
    console.log('Error in add/edit form');
    event.preventDefault();
    event.stopPropagation();
  }

  destroy() {
    this.el.removeEventListener('submit', this.#submit);
    this.abortController.abort();
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }
}

export default Form;
