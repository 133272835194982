import BaseComponent from './BaseComponent';

class CookieBanner extends BaseComponent {
  static name() { return 'CookieBanner'; }

  constructor(props, el) {
    super();
    this.props = props;
    this.el = el;
    this.$el = $(el);

    this.objects = {
      $navbar: this.$el.find('.navbar'),
      $navbarContent: this.$el.find('#navbar-content'),
    };
  }

  init() {
    console.info('~~~ Cookie Banner ~~~');

    const body = $('body');
    const { $navbarContent } = this.objects;

    // lock scrolling on body when toggle navigation is expanded
    $navbarContent
      .on('hidden.bs.collapse', () => body.removeClass('overflow-hidden'))
      .on('shown.bs.collapse', () => body.addClass('overflow-hidden'));
  }
}

export default CookieBanner;
