import { buildGet } from '../utils';
import mediator from '../services/shared/mediator';
import RefreshableComponent from './RefreshableComponent';

/**
 * CategoryContainer component class.
 */
class CategoryContainer extends RefreshableComponent {
  /**
   * Get the name of the class.
   * @returns {string} The name of the class.
   */
  static name() { return 'CategoryContainer'; }

  /**
   * Creates an instance of CategoryContainer.
   * @param {Object} options - The options for the category container.
   * @param {string} options.addCategorySelector - Selector for finding the add category button.
   * @param {string} options.categoryUrl - URL to load categories from after inputs added to DOM.
   * @param {string} options.subcategoryUrl - URL to load subcategories from after category selection.
   * @param {HTMLElement} el - The HTML element associated with the category container.
   */
  constructor({ addCategorySelector, categoryUrl, subcategoryUrl }, el) {
    super();
    this.addButton = document.querySelector(addCategorySelector);
    /** @type {(query: Object) => Promise<string | any>} */
    this.categoryApi = buildGet(categoryUrl);
    /** @type {(query: Object) => Promise<string | any>} */
    this.subcategoryApi = buildGet(subcategoryUrl);
    this.el = el;
  }

  /**
   * Initializes the CategoryContainer component.
   */
  init() {
    console.info('~~~ CategoryContainer ~~~');

    this.addButton.addEventListener('click', this.addCategory.bind(this));
    this.el.addEventListener('change', this.handleCategoryChange.bind(this));
    this.el.querySelectorAll('.remove-category-button').forEach((btn) => {
      btn.addEventListener('click', this.removeCategory.bind(this));
    });
  }

  /**
   * Add a new category to the DOM.
   * @param {Event} e - The event object.
   */
  async addCategory(e) {
    e.preventDefault();
    const categoryInputTemplate = `
      <div class="col-md-6 col-sm-6">
        <div class="form-group">
          <select class="form-control" name="categorylist" required></select>
        </div>
      </div>
      <div class="col-md-5 col-sm-5">
        <div class="form-group">
          <select class="form-control" name="subcategorylist" required></select>
        </div>
      </div>
      <div class="col-md-1 col-sm-1">
        <a href="#" class="remove-category-button" style="line-height: 40px;font-size: large;">
          <i class="fa fa-times-circle"></i>
        </a>
      </div>
    `;
    const row = document.createElement('div');
    row.className = 'row';
    row.innerHTML = categoryInputTemplate;
    this.el.appendChild(row);
    mediator.publish('unsavedChanges:add');
    // add event listener to remove button
    const removeBtn = row.querySelector('.remove-category-button');
    removeBtn.addEventListener('click', this.removeCategory.bind(this));
    // load categories from backend
    const categorySelect = row.querySelector('[name="categorylist"]');
    const subcategorySelect = row.querySelector('[name="subcategorylist"]');
    await this.loadCategories(this.categoryApi, categorySelect);
    // load subcategories from backend
    await this.loadCategories(this.subcategoryApi, subcategorySelect, { selectedCategoryId: categorySelect.value });
  }

  /**
   * Remove a category from the DOM.
   * @param {Event} e - The event object.
   */
  removeCategory(e) {
    e.preventDefault();
    /** @type {HTMLAnchorElement} */
    const removeBtn = e.target;
    // remove the category row
    const row = removeBtn.closest('.row');
    removeBtn.removeEventListener('change', this.removeCategory.bind(this));
    row.remove();
    mediator.publish('unsavedChanges:add');
  }

  /**
   * Handle the category change event.
   * @param {Event} evt - The event object.
   */
  handleCategoryChange(evt) {
    /** @type {HTMLSelectElement} */
    const categorySelect = evt.target;
    if (categorySelect.name !== 'categorylist') return;
    // Call api to load correct subcategories when category changes
    const subcategorySelect = evt.target
      .closest('.row')
      .querySelector('[name="subcategorylist"]');
    this.loadCategories(
      this.subcategoryApi,
      subcategorySelect,
      {
        selectedCategoryId: evt.target.value,
      });
  }

  /**
   * Load (sub)category data from backend and update target element.
   * @param {{(query: Object) => Promise<string | any>}} callApi - The API function to call.
   * @param {Element} target - Select element to update.
   * @param {Object} query - Query params to add to url.
   * @returns {Promise} The promise from the fetch request.
   * @description This function will call the API to load categories from the backend.
   * It will then clear the existing options and add new options to the select element.
   */
  async loadCategories(callApi, target, query) {
    // call the api to load categories from backend
    const options = await callApi(query);
    target.innerHTML = ''; // clear existing options
    options.forEach((value) => {
      // iterate over data, adding options to select
      const opt = document.createElement('option');
      opt.value = value.pk;
      opt.innerHTML = value.fields.title;
      target.appendChild(opt);
    });
  }

  /**
   * Clean up event listeners.
   */
  destroy() {
    this.addButton.removeEventListener('click', this.addCategory.bind(this));
    this.el.removeEventListener('change', this.handleCategoryChange.bind(this));
    this.el.querySelectorAll('.remove-category-button').forEach((btn) => {
      btn.removeEventListener('click', this.removeCategory.bind(this));
    });
  }
}

export default CategoryContainer;
