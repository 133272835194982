/**
 * Get the response data from the fetch response
 * @param {Response} response
 * @returns {Promise<string|Object>}
 * @throws {Error} Throws an error if the content type is not supported
 */
const getResponseData = async (response) => {
  const contentType = response.headers.get('Content-Type') ?? '';
  if (contentType.includes('application/json')) {
    return await response.json();
  }
  else if (contentType.includes('text/html')) {
    return await response.text();
  }
  else {
    throw new Error(`Unsupported content type ${contentType}`);
  }
};

/**
 * Perform a GET request
 * @param {string} url
 * @param {Object} opts
 * @param {Object} query
 * @returns {Promise<string|Object>}
 * @throws {Error} Throws an error if the response is not OK
 */
const get = async (url, opts, query) => {
  let apiUrl = `${url}`;
  if (query && typeof query === 'object') {
    const params = typeof query[Symbol.iterator] === 'function' ? query : Object.entries(query);
    const queryString = new URLSearchParams(params).toString();
    apiUrl = `${apiUrl}?${queryString}`;
  }
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  const headers = opts && opts.headers ? { ...defaultHeaders, ...opts.headers } : defaultHeaders;
  const response = await fetch(apiUrl, {
    method: 'GET',
    headers,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return getResponseData(response);
};

/**
 * Build a function for a GET request to the given URL
 * @param {string} url
 * @param {Object} opts
 * @returns {(query: Object) => Promise<string|Object>}
 */
const buildGet = (url, opts) => async query => get(url, opts, query);

/**
 * Perform a POST request
 * @param {string} url
 * @param {Object} data
 * @param {Object} opts
 * @returns {Promise<string|Object>}
 * @throws {Error} Throws an error if the response is not OK
 */
const post = async (url, data, opts) => {
  const defaultHeaders = {
    'Content-Type': 'application/json',
  };
  const headers = opts && opts.headers ? { ...defaultHeaders, ...opts.headers } : defaultHeaders;
  const response = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return getResponseData(response);
};

/**
 * Build a function that sends a POST request to the given URL
 * @param {string} url
 * @param {Object} opts
 * @returns {(data: Object) => Promise<string|Object>}
 */
const buildPost = (url, opts) => async data => post(url, data, opts);

/**
 * Perform a POST request with form data
 * @param {string} url
 * @param {FormData} data
 * @returns {Promise<string|Object>}
 * @throws {Error} Throws an error if the response is not OK
 */
const postFormData = async (url, data) => {
  const response = await fetch(url, {
    method: 'POST',
    body: data,
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return getResponseData(response);
};

/**
 * Build a function that sends a POST request with form data to the given URL
 * @param {string} url
 * @returns {(data: FormData) => Promise<string|Object>}
 */
const buildPostFormData = url => async data => postFormData(url, data);

export {
  get,
  buildGet,
  post,
  buildPost,
  postFormData,
  buildPostFormData,
};
