import RefreshableComponent from './RefreshableComponent';

class CarouselContainer extends RefreshableComponent {
  static name() { return 'CarouselContainer'; }

  constructor(props, el) {
    super();
    this.props = props;
    this.el = el;
    this.abortController = new AbortController();
  }

  init() {
    console.info('~~~ Carousel Container ~~~');

    const {
      component,
      slidesperview,
      autoslide,
      delay,
      margin,
    } = this.el.dataset;
    const swiperEl = document.getElementById(`swiper_container_${component}`);
    const prevControl = document.getElementById(`swiper_prev_${component}`);
    const nextControl = document.getElementById(`swiper_next_${component}`);

    const swiperParams = {
      pagination: {
        clickable: true,
      },
      spaceBetween: margin,
      loop: true,
      slidesPerView: 1,
      breakpoints: {
        576: {
          slidesPerView: slidesperview,
        },
      },
    };

    if (autoslide) {
      swiperParams.autoplay = { delay };
    }

    Object.assign(swiperEl, swiperParams);
    swiperEl.initialize();

    prevControl.addEventListener('click', (e) => {
      swiperEl.swiper.slidePrev();
      e.preventDefault();
    }, { signal: this.abortController.signal });

    nextControl.addEventListener('click', (e) => {
      swiperEl.swiper.slideNext();
      e.preventDefault();
    }, { signal: this.abortController.signal });
  }

  /**
   * Clean up event listeners
   * @returns {void}
   */
  destroy() {
    this.abortController.abort();
  }
}

export default CarouselContainer;
