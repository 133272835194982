import Main from './Main';
import AccordianContainer from './components/AccordianContainer';
import AddTemplate from './components/AddTemplate';
import Carousel from './components/Carousel';
import CarouselContainer from './components/CarouselContainer';
import CategoryContainer from './components/CategoryContainer';
import CookieBanner from './components/CookieBanner';
import Form from './components/Form';
import GroupSessionForm from './components/GroupSessionForm';
import Header from './components/Header';
import ImageSelector from './components/ImageSelector';
import LazyLoadContainer from './components/LazyLoadContainer';
import ModalsContainer from './components/ModalsContainer';
import NumberInput from './components/NumberInput';
import Preloader from './components/Preloader'; // Added Preloader import
import Search from './components/Search';
import SignUpForm from './components/SignUpForm';
import PractitionerEditProfile from './components/PractitionerEditProfile';
import PractitionerSignUp from './components/PractitionerSignUp';
import PractitionerSetup from './components/PractitionerSetup';
import SortableContainer from './components/SortableContainer';
import Trigger from './components/Trigger';

// mediator
import mediator from './services/shared/mediator';

/** Styles * */
import './styles/app.scss';

console.info('Sofia Health');

const modules = [
  AccordianContainer,
  AddTemplate,
  Carousel,
  CarouselContainer,
  CategoryContainer,
  CookieBanner,
  GroupSessionForm,
  Form,
  Header,
  ImageSelector,
  LazyLoadContainer,
  ModalsContainer,
  NumberInput,
  Preloader,
  Search,
  SignUpForm,
  PractitionerEditProfile,
  PractitionerSignUp,
  PractitionerSetup,
  SortableContainer,
  Trigger,
];

const core = new Main(modules);
core.init();

window.refreshModules = () => core.refresh();
window.mediator = mediator; // add mediator to global scope for legacy code
