/**
 * Returns the modal options for the specified modal name.
 * @param {string} modalName
 * @returns {Promise<import('./ModalOptions').ModalOptions>}
 * @throws {Error} Throws an error if the modal options for the specified modal name are not found.
 */
export async function modalOptionsFactory(modalName) {
  switch (modalName) {
    case 'deleteJob':
      return (await import('../job/DeleteJobModalOptions')).default;
    default:
      throw new Error(`Modal options for ${modalName} not found`);
  }
}
