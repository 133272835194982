class PractitionerEditProfile {
  static name() { return 'PractitionerEditProfile'; }

  constructor(props, el) {
    this.props = props;
    this.el = el;
    this.$el = $(el);

    this.objects = {
      $form: this.$el.find('form'),
      $horizontalAlignment: this.$el.find('[name="backpic_horizontal_alignment"]'),
      $verticalAlignment: this.$el.find('[name="backpic_vertical_alignment"]'),
      $imageHeight: this.$el.find('[name="backpic_image_height"]'),
      $imagePreview: this.$el.find('[data-image-preview]'),
    };

    this.handleHorizontalAlignmentChange = (event) => {
      const { $imagePreview } = this.objects;
      const selectedAlignment = event.target.value;
      $imagePreview.css('background-position-x', selectedAlignment);
    };

    this.handleVerticalAlignmentChange = (event) => {
      const { $imagePreview } = this.objects;
      const selectedAlignment = event.target.value;
      $imagePreview.css('background-position-y', selectedAlignment);
    };

    this.handleImageHeightChange = (event) => {
      const { $imagePreview } = this.objects;
      const $target = $(event.target);
      const selectedHeight = $target.val();
      $target.find('option').each(function () {
        $imagePreview.removeClass($(this).val());
      });

      $imagePreview.addClass(selectedHeight);
    };
  }

  init() {
    console.info('~~~ PractitionerEditProfile ~~~');
    const { $horizontalAlignment, $verticalAlignment, $imageHeight } = this.objects;

    $horizontalAlignment.on('change', this.handleHorizontalAlignmentChange);
    $verticalAlignment.on('change', this.handleVerticalAlignmentChange);
    $imageHeight.on('change', this.handleImageHeightChange);
  }
}

export default PractitionerEditProfile;
