import mediator from '../services/shared/mediator';
import RefreshableComponent from './RefreshableComponent';

/**
 * Preloader component class.
 */
class Preloader extends RefreshableComponent {
  static name() { return 'Preloader'; }

  /**
   * Creates an instance of Preloader.
   * @param {*} _
   * @param {HTMLFormElement} el - The element to apply the preloader to.
   */
  constructor(_, el) {
    super();
    this.el = el;
    this.subscriptions = [];
  }

  /**
   * Initializes the Preloader component.
   */
  init() {
    console.info('~~~ Preloader ~~~');

    this.el.addEventListener('animationend', () => {
      if (this.el.classList.contains('fade-out')) {
        this.el.classList.add('d-none');
      }
    });
    // Hide preloader on load
    this.fadeOut();

    // Subscribe to preloader show and hide events
    this.subscriptions.push(mediator.subscribe('preloader:show', this, this.fadeIn));
    this.subscriptions.push(mediator.subscribe('preloader:hide', this, this.fadeOut));
  }

  /**
   * Fades in the preloader element.
   */
  fadeIn() {
    this.el.classList.remove('fade-out');
    this.el.classList.remove('d-none');
    this.el.classList.add('fade-in');
  }

  /**
   * Fades out the preloader element.
   */
  fadeOut() {
    this.el.classList.remove('fade-in');
    this.el.classList.add('fade-out');
  }

  destroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }
}

export default Preloader;
