import BaseComponent from './BaseComponent';

class RefreshableComponent extends BaseComponent {
  static get refreshable() { return true; }

  /**
   * Clean up event listeners and other resources
   */
  destroy() {
    throw new Error('destroy() not implemented');
  }
}

export default RefreshableComponent;
