import { modalOptionsFactory } from '../services';

/** @typedef {import('../services/shared/ModalOptions').default} ModalOptions */

/**
 * @description Modal component to handle display and state of a modal dialog
 * @class Modal
 */
class Modal {
  /**
   * @description Creates an instance of Modal
   * @param {HTMLElement} modalElement - The DOM element for the modal
   * @param {object} props - Properties passed to all modals
   */
  constructor(modalElement, props) {
    if (!(modalElement instanceof HTMLElement)) {
      throw new TypeError('modalElement must be an instance of HTMLElement');
    }

    const { modalName, modalProps, ...localProps } = modalElement.dataset;
    /** @type {Object | Array} */
    const settings = modalProps ? JSON.parse(modalProps) : {};

    this.modalElement = modalElement;
    this.modalName = modalName;
    this.props = { ...props, ...localProps, settings: settings };
    this.isShown = false;
    /** @type {Promise<ModalOptions>} */
    this.options = modalOptionsFactory(this.modalName)
      .then(options => new options(modalElement, this.props));
  }

  /**
   * @description Shows the modal
   */
  async show() {
    await this.options.then(o => o.postShow());
    this.modalElement.style.display = 'block';
    this.modalElement.classList.add('show');
    this.modalElement.ariaHidden = false;
    document.body.classList.add('modal-open');
    this.isShown = true;
    await this.options.then(o => o.postShow());
  }

  /**
   * @description Hides the modal
   */
  async hide() {
    await this.options.then(o => o.preHide());
    this.modalElement.style.display = 'none';
    this.modalElement.classList.remove('show');
    this.modalElement.ariaHidden = true;
    document.body.classList.remove('modal-open');
    this.isShown = false;
    await this.options.then(o => o.postHide());
  }

  /**
   * @description Toggles the modal visibility
   */
  async toggle() {
    if (this.isShown) {
      await this.hide();
    }
    else {
      await this.show();
    }
  }

  destroy() {
    this.options.then(o => o.destroy());
  }
}

export default Modal;
