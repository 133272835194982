export function renderManualSessionInput(minDate) {
  return `
    <div class="form-inline d-flex flex-row justify-content-between align-items-center mt-3">
      <div class="form-group">
        <label for="manual_entry_start_date">Date</label>
        <input type="date" class="form-control ml-2" name="manual_entry_start_date" id="manual_entry_start_date" value="" min="${minDate}" required />
      </div>
      <div class="form-group">
        <label for="manual_entry_start_time">Start</label>
        <input type="time" class="form-control ml-2" name="manual_entry_start_time" id="manual_entry_start_time" value="" required />
      </div>
      <a href="#" class="manual-remove-btn" title="Remove Session">
        <i class="fa fa-times-circle" style="font-size:20px;color:red;"></i>
      </a>
    </div>
    <p data-status="active" class="text-danger error-container pl-3 pt-2"></p>
  `;
}

export function renderScheduleInput(dayId) {
  return `
    <div id="schedule" class="form-row mb-1">
      <div class="col-3 d-flex flex-row justify-content-end align-items-center">
        <label for="${dayId}_start">Start Time</label>
      </div>
      <div class="col-4">
        <input type="time" aria-label="${dayId}_start" name="${dayId}_start" class="form-control" value="" required>
      </div>
      <div class="col-5 d-flex flex-row justify-content-end align-items-center">
        <a href="#" id="${dayId}_remove" class="day-remove-btn mr-5">
          <i class="fa fa-times-circle" style="font-size:20px;margin-top:10px;color:red;"></i>
        </a>
      </div>
    </div>
    <p data-status="active" class="error-container text-danger" style="display: none;"></p>
  `;
}

export function setDisplayState(count, wrapper, dayId) {
  if (count < 1) {
    wrapper.insertAdjacentHTML('beforeend', '</div><div id="unavailable" class="row"><div class="col-md-12"><h6 style="font-weight: 500;color: #999;">&nbsp;-&nbsp;Not Scheduled</h6></div></div>');
    document.getElementById(`${dayId}_copy`).querySelector('.dropbtn').style.cursor = 'default';
    document.getElementById(`${dayId}_copy`).querySelector('.dropdown-content').style.display = 'none';
    document.getElementById(`${dayId}_copy`).querySelector('.dropbtn .fa.fa-copy').style.color = '#999';
  }
}

export function showInlineScheduleErrors(conflicts, sessionType) {
  const errorMsg = 'This time conflicts with at least one event on your calendar.';
  Array.from(document.querySelectorAll('.error-container[data-status="active"]')).forEach((el) => {
    el.innerHTML = '';
    el.style.display = 'none';
    const input = el.previousElementSibling.querySelector('input');
    // clear custom validity if input is not focused
    if (document.activeElement !== input) {
      input.setCustomValidity('');
      input.reportValidity();
    }
  });
  conflicts.forEach((conflict) => {
    let errorContainer = null;
    if (sessionType === '2') {
      errorContainer = document.querySelector(`#manual-entry-session-form .error-container[data-status="active"]:nth-of-type(${conflict.index + 1})`);
    }
    else {
      const dayWrapper = document.getElementById(conflict.day);
      const index = Array.from(dayWrapper.getElementsByTagName('input')).findIndex(el => el.value === conflict.time);
      errorContainer = dayWrapper.querySelector(`.error-container[data-status="active"]:nth-of-type(${index + 1})`);
    }
    const errorEl = document.createElement('span');
    errorEl.textContent = conflict.message;
    errorEl.style.display = 'block';
    errorEl.className = 'fine mb-2';
    errorContainer.appendChild(errorEl);
    errorContainer.style.display = 'block';
    const input = errorContainer.previousElementSibling.querySelector('input');
    input.setCustomValidity(errorMsg);
    input.reportValidity();
  });
}

export function validateSessionTimes(apiCall, opts) {
  const { scroll: shouldScroll } = opts || { scroll: true };

  function scroll(el) {
    if (shouldScroll) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }

  const groupSessionsEnabled = document.getElementById('group_sessions_enabled');
  if (!groupSessionsEnabled.checked) {
    return;
  }

  const invalidTimesMessage = document.getElementById('conflicts-message');
  const noSessionsGeneratedMessage = document.getElementById('no-sessions-generated-message');
  const noSessionsMessage = document.getElementById('no-sessions-message');
  const sessionType = document.getElementById('session_type');
  const noManualSessionTimes = Array.from(document.getElementById('manual-entry-session-form').querySelectorAll('input')).length === 0;
  const manualSessionSelected = sessionType.value === '2';
  const noRecurringSessionTimes = Array.from(document.getElementById('recurring-session-form').querySelectorAll('input[type="time"]')).length === 0;
  const recuringSessionSelected = sessionType.value === '1';

  const missingSessionTimes = (manualSessionSelected && noManualSessionTimes)
    || (recuringSessionSelected && noRecurringSessionTimes);
  if (missingSessionTimes) {
    invalidTimesMessage.style.display = 'none';
    noSessionsGeneratedMessage.style.display = 'none';
    noSessionsMessage.style.display = 'block';
    scroll(noSessionsMessage);
    return;
  }
  noSessionsMessage.style.display = 'none';

  const body = new FormData(document.getElementById('add_service_form'));
  apiCall(body)
    .then((data) => {
      if (data.status === 'VALID') {
        invalidTimesMessage.style.display = 'none';
        noSessionsGeneratedMessage.style.display = 'none';
        showInlineScheduleErrors([], sessionType.value);
      }
      else if (data.status === 'NO_SESSIONS_GENERATED') {
        invalidTimesMessage.style.display = 'none';
        noSessionsGeneratedMessage.style.display = 'block';
        scroll(noSessionsGeneratedMessage);
      }
      else if (data.status === 'SCHEDULE_CONFLICT') {
        noSessionsGeneratedMessage.style.display = 'none';
        invalidTimesMessage.style.display = 'block';
        scroll(invalidTimesMessage);
        showInlineScheduleErrors(data.conflicts, sessionType.value);
      }
    })
    .catch((error) => {
      console.log(error);
    });
}
