import RefreshableComponent from './RefreshableComponent';
import Modal from './Modal';
import mediator from '../services/shared/mediator';

/**
 * @class ModalsContainer
 * @extends RefreshableComponent
 * @description Manages modal elements, showing and closing them through mediator subscriptions.
 * This module manages the bootstrap 4 modal functionality without referencing jquery.
 */
class ModalsContainer extends RefreshableComponent {
  static name() { return 'ModalsContainer'; }

  /**
   * Creates an instance of ModalsContainer.
   * @param {object} props - The properties of the component.
   * @param {HTMLElement} el - The DOM element for the component.
   */
  constructor(props, el) {
    super(props, el);
    this.props;
    this.el = el;
    /** @type {Record<string, Modal>} */
    this.modals = {};
    this.subs = [];
    this.backdrop = (() => {
      const backdropEl = document.createElement('div');
      backdropEl.classList.add('modal-backdrop', 'fade');
      return {
        backdropEl,
        show: () => {
          document.body.appendChild(backdropEl);
          setTimeout(() => backdropEl.classList.add('show'), 0);
        },
        hide: () => {
          backdropEl.classList.remove('show');
          setTimeout(() => document.body.removeChild(backdropEl), 150);
        },
      };
    })();
  }

  /**
   * Initializes the modal management by subscribing to show and close events.
   */
  init() {
    const modalEls = this.el.querySelectorAll('[data-modal]');
    Array.from(modalEls).forEach((modalEl) => {
      const modal = new Modal(modalEl, this.props);
      this.modals[modal.modalName] = modal;
    });

    this.subs.push(mediator.subscribe(showModalChannel, this, this.showModal));
    this.subs.push(mediator.subscribe(closeModalChannel, this, this.closeModal));
  }

  /**
   * Shows a modal by adding the 'show' class.
   * @param {string} modalName - The name of the modal to show.
   */
  showModal(_, event, { modalName }) {
    if (event) {
      event.preventDefault();
    }
    const modal = this.modals[modalName];
    if (modal) {
      modal.show();
      this.backdrop.show();
      this.backdrop.backdropEl.addEventListener('click', e => this.closeModal(e.target, e, { modalName }));
    }
  }

  /**
   * Closes a modal by removing the 'show' class.
   * @param {string} modalName - The ID of the modal to close.
   */
  closeModal(_, event, { modalName }) {
    if (event) {
      event.preventDefault();
    }
    const modal = this.modals[modalName];
    if (modal) {
      modal.hide();
      this.backdrop.hide();
      this.backdrop.backdropEl.removeEventListener('click', e => this.closeModal(e.target, e, { modalName }));
    }
  }

  /**
   * Clean up event listeners and other resources.
   */
  destroy() {
    this.subs.forEach(sub => sub.unsubscribe());
    Object.values(this.modals).forEach(modal => modal.destroy());
  }
}

export default ModalsContainer;

const showModalChannel = 'showModal';
const closeModalChannel = 'closeModal';

export { showModalChannel, closeModalChannel };
