/**
 * Creates an error banner element.
 *
 * @returns {HTMLDivElement} The created error banner element.
 */
export function createErrorBanner() {
  const errorBanner = document.createElement('div');
  errorBanner.classList.add('alert', 'alert-danger', 'alert-dismissible', 'fade', 'show');
  errorBanner.setAttribute('role', 'alert');
  errorBanner.style.display = 'none';
  errorBanner.innerText = 'Please correct the errors below and try again.';
  return errorBanner;
}

/**
 * Returns the form options for the specified form name.
 * @param {string} formName
 * @returns {Promise<import('./FormOptions').FormOptions>}
 * @throws {Error} Throws an error if the form options for the specified form name are not found.
 */
export async function formOptionsFactory(formName) {
  switch (formName) {
    case 'job':
      return (await import('../job/JobFormOptions')).default;
    default:
      throw new Error(`Form options for ${formName} not found`);
  }
}
