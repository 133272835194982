/**
 * Base class for components
 */
class BaseComponent {
  static name() { throw new Error('name() not implemented'); }

  /**
   * Base class for components
   * @param {any} props - properties of the component
   * @param {Element} el - the element to attach the component to
   */
  constructor() { }

  /**
   * Initialize the component
   * @abstract
   * @returns {void}
   */
  init() {
    throw new Error('init() not implemented');
  }
}

export default BaseComponent;
