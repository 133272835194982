import RefreshableComponent from './RefreshableComponent';

/**
 * @description NumberInput component for handling numeric inputs
 * @param {Object} props - Properties for the component
 * @param {HTMLElement} element - The DOM element to attach the component to
 * @returns {void}
 * @example
 * // Initialize NumberInput component
 * <input type="number" data-module="NumberInput" min="0" data-minimum-positive-value="0.5" ... />;
 */
class NumberInput extends RefreshableComponent {
  static name() { return 'NumberInput'; }

  constructor({
    minimumPositiveValue, // minimum positive value allowed - use when the min value can either be 0 or some positive value
  }, element) {
    super();
    /** @type {number | undefined} */
    this.minimumPositiveValue = minimumPositiveValue;
    /** @type {HTMLElement} */
    this.element = element;
  }

  /**
   * Initialize the component
   * @returns {void}
   */
  init() {
    console.info('~~~ Number Input ~~~');

    // this check will need to be updated if we add other attributes
    if (this.minimumPositiveValue) {
      this.element.setAttribute('min', '0');
      this.element.addEventListener('change', this.handleChange.bind(this));
    }
  }

  /**
   * Handle input event
   * @param {Event} event - The input event
   * @returns {void}
   */
  handleChange(event) {
    const value = parseFloat(event.target.value, 0.0);
    if (value > 0 && value < this.minimumPositiveValue) {
      event.target.value = this.minimumPositiveValue;
    }
  }

  /**
   * Clean up event listeners
   * @returns {void}
   */
  destroy() {
    this.element.removeEventListener('input', this.handleChange.bind(this));
  }
}

export default NumberInput;
