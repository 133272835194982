class SortableContainer {
  static name() { return 'SortableContainer'; }

  constructor(props, el) {
    this.props = props;
    this.el = el;
    this.$el = $(el);
    this.sortable = {};
    this.initialOrder = [];

    this.objects = {
      $sortableContainer: this.$el.find('[data-sortable-container]'),
      $wrapper: this.$el.find('[data-sortable-wrapper]'),
      $actions: this.$el.find('[data-sortable-actions]'),
      $saveButton: this.$el.find('[data-sortable-save]'),
      $resetButton: this.$el.find('[data-sortable-reset]'),
      $errors: this.$el.find('[data-sortable-errors]'),
      $hiddenInput: this.$el.find('[data-sortable-hidden-input]'),
    };

    // removes do-not-sort items from sortable array as they
    // are still tracked with the use of filter and onMove
    this.getSortOrder = () => {
      const {
        $sortableContainer,
      } = this.objects;
      const fullSortOrder = this.sortable.sortable('toArray');
      const sortableItemsSortOrder = [];
      const sortableTrackedElements = $sortableContainer.children();
      fullSortOrder.forEach((item, i) => {
        if (!sortableTrackedElements.get(i).classList.contains('do-not-sort')) {
          sortableItemsSortOrder.push(item);
        }
      });

      return sortableItemsSortOrder;
    };

    this.handleOnUpdate = () => {
      const {
        $wrapper,
        $actions,
        $errors,
        $hiddenInput,
        $saveButton,
      } = this.objects;

      if ($errors.length) {
        $errors.addClass('d-none');
        $errors.html('');
      }

      if (JSON.stringify(this.initialOrder) === JSON.stringify(this.sortable.sortable('toArray'))) {
        this.resetUI();
      }
      else {
        if ($hiddenInput.length) {
          $hiddenInput.val(this.getSortOrder());
        }

        if ($saveButton.length) {
          window.addEventListener('beforeunload', this.unloadPage);
        }

        if ($actions.length) {
          $wrapper.addClass('sortable-wrapper-editing');
          $actions.removeClass('d-none');
        }
      }
    };

    this.handleSave = () => {
      const {
        $errors,
        $saveButton,
        $resetButton,
      } = this.objects;

      const url = $saveButton.data('url');
      const { unloadPage } = this;

      $saveButton.prop('disabled', true);
      $resetButton.prop('disabled', true);

      $.ajax({
        url,
        type: 'POST',
        data: {
          order: this.getSortOrder(),
        },
        success() {
          window.removeEventListener('beforeunload', unloadPage);
          window.location.reload();
        },
        error(xhr, errmsg) {
          $saveButton.prop('disabled', false);
          $resetButton.prop('disabled', false);
          $errors.html(`Oops! We have encountered an error: ${errmsg}`);
          $errors.removeClass('d-none');
          console.log(`${xhr.status}: ${xhr.responseText}`);
        },
      });
    };

    this.handleReset = () => {
      this.sortable.sortable('sort', this.initialOrder, true);
      this.resetUI();
    };

    this.resetUI = () => {
      const {
        $wrapper,
        $actions,
        $errors,
        $saveButton,
        $hiddenInput,
      } = this.objects;

      if ($hiddenInput.length) {
        $hiddenInput.val('');
      }

      if ($saveButton.length) {
        window.removeEventListener('beforeunload', this.unloadPage);
      }

      if ($actions.length) {
        $wrapper.removeClass('sortable-wrapper-editing');
        $actions.addClass('d-none');
      }

      if ($errors.length) {
        $errors.addClass('d-none');
        $errors.html('');
      }
    };

    this.unloadPage = (e) => {
      e.preventDefault();

      // Included for legacy support, e.g. Chrome/Edge < 119
      e.returnValue = true;

      return 'You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?';
    };
  }

  init() {
    const {
      $sortableContainer,
      $saveButton,
      $resetButton,
    } = this.objects;

    console.info('~~~ SortableContainer ~~~');
    this.sortable = $sortableContainer.sortable({
      animation: 150,
      onUpdate: this.handleOnUpdate,
      filter: '.do-not-sort', // filter and onMove used instead of draggable as draggable causes do-not-sort items to be moved to start of list on reset
      onMove(evt) {
        return evt.related.className.indexOf('do-not-sort') === -1;
      },
    });
    this.initialOrder = this.sortable.sortable('toArray');

    if ($saveButton.length) {
      $saveButton.on('click', this.handleSave);
    }

    if ($resetButton.length) {
      $resetButton.on('click', this.handleReset);
    }
  }
}

export default SortableContainer;
