import BaseComponent from './BaseComponent';

class Header extends BaseComponent {
  static name() { return 'Header'; }

  constructor(props, el) {
    super();
    this.props = props;
    this.el = el;
    this.$el = $(el);

    this.objects = {
      $navbar: this.$el.find('.navbar'),
      $navbarContent: this.$el.find('#navbar-content'),
    };
  }

  init() {
    console.info('~~~ Header ~~~');

    const body = $('body');
    const { $navbarContent } = this.objects;

    // lock scrolling on body when toggle navigation is expanded
    $navbarContent
      .on('hidden.bs.collapse', () => body.removeClass('overflow-hidden'))
      .on('shown.bs.collapse', () => body.addClass('overflow-hidden'));

    $('.dropdown-menu a[data-toggle="dropdown"]').on('click', ({ target }) => {
      const $target = $(target);
      if (!$target.next().hasClass('show')) {
        $target.parents('.dropdown-menu').first().find('.show')
          .removeClass('show');
      }
      const $subMenu = $target.next('.dropdown-menu');
      $subMenu.toggleClass('show');
      $target.parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', () => {
        $('.dropdown-submenu .show').removeClass('show');
      });
      return false;
    });

    $('.sub-nav[data-toggle="sub-nav-dropdown"]').on('mouseenter', ({ target }) => {
      const $target = $(target);
      const $subMenu = $target.next('.dropdown-menu');
      $subMenu.toggleClass('show');
      return false;
    }).on('mouseleave', ({ target }) => {
      const $target = $(target);
      $target.next('.dropdown-menu').removeClass('show');
      return false;
    });
  }
}

export default Header;
