import { lazyLoadingOptionsFactory } from '../services/shared/lazyLoadingUtils';
import RefreshableComponent from './RefreshableComponent.js';

/**
 * @typedef {import('../services/shared/LazyLoadingOptions.js').default} LazyLoadingOptions
 */

class LazyLoadContainer extends RefreshableComponent {
  static name() { return 'LazyLoadContainer'; }

  /** @type {LazyLoadingOptions} */
  #lazyLoadOptions = null;

  /**
   * Creates a new LazyLoadContainer instance.
   * @param {object} options
   * @param {string} options.optionsName - The name of the lazy loading options.
   * @param {number} [options.threshold=0.1] - The threshold for the intersection observer.
   * @param {HTMLElement} [options.root=null] - The root element for the intersection observer.
   * @param {string} [options.rootMargin='1px'] - The root margin for the intersection observer.
   * @param {object} props - Extra options added to the lazy loading options.
   * @param {HTMLElement} el
   * @throws {Error} Throws an error if the options name is not provided.
   * @throws {Error} Throws an error if the options are not found.
   */
  constructor({
    optionsName,
    threshold = 0.1,
    root = null,
    rootMargin = '1px',
    ...props
  }, el) {
    super();
    this.loadOptions = lazyLoadingOptionsFactory(optionsName).then((options) => {
      this.#lazyLoadOptions = new options(props);
      return this.#lazyLoadOptions;
    });
    this.threshold = threshold;
    this.root = root;
    this.rootMargin = rootMargin;
    this.el = el;
    this.isVisible = false;
  }

  init() {
    console.info('~~~ LazyLoadContainer ~~~');

    const observer = new IntersectionObserver(
      (entries) => {
        // sort by entry time
        const targets = entries
          .sort((a, b) => a.time - b.time)
          .filter(e => e.isIntersecting)
          .map((e) => {
            const { target } = e;
            observer.unobserve(target);
            return target;
          });

        this.#lazyLoadOptions.generateUI(targets);
      },
      {
        root: this.root,
        rootMargin: this.rootMargin,
        threshold: this.threshold,
      },
    );

    this.loadOptions.then(options => options.init(this.el, observer));
    this.observer = observer;
  }

  destroy() {
    this.observer.disconnect();
  }
}

export default LazyLoadContainer;
