class SignUpBase {
  static name() { return 'SignUpBase'; }

  constructor(props, el) {
    this.props = props;
    this.el = el;
    this.$el = $(el);

    this.objects = {
    };

    // displays error message for invalid input
    this.handleInvalid = (e) => {
      const { currentTarget } = e;
      const $currentTarget = $(currentTarget);
      const $error = $currentTarget.parents('.form-group').find('.invalid-feedback');
      if (currentTarget.validity.customError) {
        $error.html(currentTarget.validationMessage);
      }

      if (e.data?.missingErrorMessage && currentTarget.validity.valueMissing) {
        $error.html(e.data?.missingErrorMessage);
      }
    };

    // clear ereror messages on input
    this.handleInputReset = (e) => {
      const { currentTarget } = e;
      const $currentTarget = $(currentTarget);
      const resetAnotherField = e.data?.resetAnotherField;

      currentTarget.setCustomValidity('');
      $currentTarget.removeClass('is-invalid');

      if (resetAnotherField) {
        resetAnotherField.get(0).setCustomValidity('');
        resetAnotherField.removeClass('is-invalid');
      }
    };

    this.validateEmailAsync = async () => {
      const { $emailField, $emailConfirmField } = this.objects;
      // If user is a customer already form is rendered with email
      // field disabled as the account is already created so skip validation
      if ($emailField.prop('disabled')) {
        return true;
      }

      // Check if email and confirm email are the same
      const emailVal = $emailField.val().toLowerCase();
      const emailConfirmVal = $emailConfirmField.val().toLowerCase();
      const element = $emailField.get(0);

      if (emailVal !== emailConfirmVal) {
        element.setCustomValidity('The emails you entered do not match.');
        return false; // No need to check further
      }

      try {
        const response = await $.ajax({
          url: '/checkusername/',
          type: 'POST',
          data: {
            email: $emailField.val(),
          },
        });

        const rtnValue = JSON.stringify(response).substring(1, 2);
        if (rtnValue !== '0') {
          element.setCustomValidity('An account with that email already exists, please enter a different email.');
          return false;
        }
      }
      catch (error) {
        console.error('Error validating email:', error);
        element.setCustomValidity('There was an error validating the promo code.');
        return false;
      }

      element.setCustomValidity('');
      return true;
    };

    this.validatePassword = () => {
      const { $passwordField, $passwordConfirmField } = this.objects;

      // If user is a customer already form is rendered without password
      // fields required as the account is already created so skip validation
      if (!$passwordField.prop('required')) {
        return true;
      }

      const pwVal = $passwordField.val();
      const pwConfirmVal = $passwordConfirmField.val();
      const element = $passwordConfirmField.get(0);

      if (pwVal !== pwConfirmVal) {
        // element.validity.customError = true;
        element.setCustomValidity('Your password entries do not match.');

        return false;
      }

      return true;
    };

    this.getCookie = (name) => {
      let cookieValue = null;
      if (document.cookie && document.cookie !== '') {
        let cookies = document.cookie.split(';');
        cookies = cookies.map(item => item.trim());
        cookieValue = decodeURIComponent(
          cookies.find(cookie => cookie.startsWith(`${name}=`))
            ?.substring(name.length + 1),
        );
      }

      return cookieValue;
    };

    this.logHS = (data) => {
      $.ajax({
        url: '/save-HS/',
        type: 'POST',
        async: false,
        data: JSON.stringify(data),
        success() {
          // Nothing to do
        },
        error(xhr) {
          console.log(xhr.responseText);
        },
      });
    };

    // submits step info and handles errors
    // if successful user will be redirected to url if returned from endpoint
    // if no url is returned successCallBack will be called

    this.submitStep = (
      url,
      $stepSubmit,
      $errorMessages,
      $errorMessageTemplate,
      successCallBack,
    ) => {
      const { $form } = this.objects;
      const { renderErrorMessages, hideSpinner } = this;
      $.ajax({
        url,
        type: 'POST',
        data: $form.serialize(),
        async: false,
        success(json) {
          $errorMessages.html('');
          if (json.success) {
            if (json.url) {
              window.location.href = json.url;
            }
            else {
              successCallBack(json);
            }
          }
          else {
            renderErrorMessages(json.errorMessages, $errorMessages, $errorMessageTemplate);
            $stepSubmit.prop('disabled', false);
            hideSpinner();
          }
        },
        error(xhr) {
          renderErrorMessages(['Oops! We have encountered an error.'], $errorMessages, $errorMessageTemplate);
          console.log(`${xhr.status}: ${xhr.responseText}`);
          $stepSubmit.prop('disabled', false);
          hideSpinner();
        },
      });
    };

    this.renderErrorMessages = (errorMessages, $errorMessagesElement, $errorMessageTemplate) => {
      const template = $errorMessageTemplate.html();
      $errorMessagesElement.html('');
      if (errorMessages.length > 0) {
        $.each(errorMessages, (_, errorMessage) => {
          const errorMessageItem = template.replace('[[message]]', errorMessage);
          $errorMessagesElement.append(errorMessageItem);
        });
      }
      else {
        const errorMessageItem = template.replace('[[message]]', 'Oops! We have encountered an error.');
        $errorMessagesElement.append(errorMessageItem);
      }

      $errorMessagesElement.removeClass('d-none');
    };

    this.showSpinner = () => {
      $('#preloader').fadeIn();
      $('[data-loader="circle-side"]').fadeIn();
    };

    this.hideSpinner = () => {
      $('[data-loader="circle-side"]').fadeOut();
      $('#preloader').fadeOut();
    };
  }
}

export default SignUpBase;
