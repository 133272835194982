import mediator from '../services/shared/mediator';
import RefreshableComponent from './RefreshableComponent';

/**
 * The AddTemplate component provides functionality to add content
 * dynamically to the page from a given template.
 */
class AddTemplate extends RefreshableComponent {
  /**
   * Get the name of the class.
   * @returns {string} The name of the class.
   */
  static name() { return 'AddTemplate'; }

  /**
   * Create an AddTemplate.
   * @param {Object} options - The options for the template.
   * @param {string} options.addButtonId - The ID of the add button (required).
   * @param {number} options.maxCount - The maximum number of templates that can be added (optional).
   * Set to 0 for no limit. Default is 10.
   * @param {number} options.initialCount - The initial number of templates (optional). Default is 0.
   * @param {string} options.templateId - The ID of the template element (required).
   * the contents of the template should contain a single child element wrapping the contents.
   * @param {HTMLElement} el - The HTML element associated with the template.
   * @description This component will look for an element inside the template
   * with the attribute data-command="remove" to determine which element to
   * attach the remove function to its click event.
   */
  constructor({ addButtonId, templateId, maxCount = 10, initialCount = 0 }, el) {
    super();
    if (!templateId || !addButtonId) {
      throw new Error('AddTemplate requires a templateId and addButtonId.');
    }

    /** @type {HTMLTemplateElement} */
    this.template = document.querySelector(`#${templateId}`);
    if (!this.template) {
      throw new Error(`Template with ID ${templateId} not found.`);
    }

    this.addButton = el.querySelector(`#${addButtonId}`);
    if (!this.addButton) {
      throw new Error(`Add button with ID ${addButtonId} not found.`);
    }

    this.maxCount = maxCount;
    this.currentCount = Number(initialCount) || 0;
    this.subscriptions = [];

    this.el = el;
  }

  /**
   * Initialize the template.
   */
  init() {
    console.info('~~~ Add Template Command ~~~');

    const addButtonClickHandler = this.appendTemplate.bind(this);
    this.addButton.addEventListener('click', addButtonClickHandler);

    this.subscriptions.push(mediator.subscribe('addTemplate:addRequest', this, this.appendTemplate));
    this.subscriptions.push(mediator.subscribe('addTemplate:removeRequest', this, this.removeTemplate));
  }

  /**
   * Add a new template instance to the DOM.
   * Clones the template content and appends it to the associated element.
   * If the cloned content contains an element with the attribute data-command="remove",
   * attaches a click event listener to remove the cloned content from the DOM.
   * @param {Event} event - The event that triggered the function (optional).
   */
  appendTemplate(_, event) {
    if (event) {
      event.preventDefault();
    }
    // if the max count is reached, do not add more templates
    if (this.maxCount > 0 && this.maxCount <= this.currentCount) return;

    /** @type {HTMLElement} */
    const clone = this.template.content.cloneNode(true);

    const removeButton = clone.querySelector('[data-command="remove"]');
    if (removeButton) {
      const removeTemplateHandler = this.removeTemplate.bind(this, clone.children[0]);
      removeButton.addEventListener('click', removeTemplateHandler);
    }

    this.el.appendChild(clone);
    // increment the current count
    this.currentCount += 1;
    mediator.publish('addTemplate:added', clone.children[0]);
  }

  /** Remove the template from the DOM
   * @param {HTMLElement} child - The child element to be removed.
   * @param {Event} e - The event that triggered the function.
   */
  removeTemplate(child, event) {
    if (event) {
      event.preventDefault();
    }
    if (!child || !this.el.contains(child)) {
      return;
    }
    this.el.removeChild(child);
    // decrement the current count
    this.currentCount -= 1;
    mediator.publish('addTemplate:removed', child);
  }

  /**
   * Clean up event listeners.
   */
  destroy() {
    this.addButton.removeEventListener('click', this.appendTemplate);
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}

export default AddTemplate;
