/**
 * @typedef {import('./components/BaseComponent').default} BaseComponent
 */

import RefreshableComponent from './components/RefreshableComponent';
import mediator from './services/shared/mediator';

class Main {
  constructor(moduleList) {
    const modules = moduleList.reduce((obj, x) => {
      const reduced = { ...obj, [x.name()]: x };
      return reduced;
    }, Object.create(null));

    /** @type {Record<string, BaseComponent>} */
    this.runningModules = {};

    this.instantiateModules = ({ selector = 'body', refresh = false }) => {
      const root = document.querySelector(selector);

      root.querySelectorAll('[data-module]').forEach((el) => {
        const { module, ...props } = el.dataset;

        // only run refreshable modules when refresh is true
        if (modules[module] && (!refresh || modules[module].refreshable)) {
          if (props.init !== false) {
            const instance = new modules[module](props, el);
            instance.init();
            this.runningModules[module] = instance;
          }
        }
      });
    };

    mediator.subscribe('components:refresh', this, this.refresh);
  }

  init() {
    this.instantiateModules({});
  }

  refresh() {
    // clean up existing, refreshable modules
    Object.values(this.runningModules)
      .filter(module => module instanceof RefreshableComponent)
      .forEach(module => module.destroy());
    // re-instantiate all refreshable modules
    this.instantiateModules({ refresh: true });
  }
}

export default Main;
