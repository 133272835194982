import RefreshableComponent from './RefreshableComponent.js';

const MAX_SIZE = 10 * 1000000; // 10 MB

class ImageSelector extends RefreshableComponent {
  static name() { return 'ImageSelector'; }

  constructor({ name, errorSelector }, el) {
    super();
    this.el = el;
    /** @type {HTMLInputElement} */
    this.fileInput = el.querySelector('input[type="file"]');
    this.preview = el.querySelector(`#${name}_preview`);
    this.editorHolder = el.querySelector(`#${name}_editHolder`);
    this.uploadHolder = el.querySelector(`#${name}_uploadHolder`);
    this.clearButton = el.querySelector(`#${name}_clearButton`);
    this.errorEl = document.querySelector(errorSelector);
  }

  init() {
    console.info('~~~ Image Selector ~~~');

    this.fileInput.addEventListener('change', this.#dragNdrop.bind(this));
    this.fileInput.addEventListener('drop', this.#drop.bind(this));
    this.fileInput.addEventListener('dragover', this.#drag.bind(this));
    this.fileInput.addEventListener('dragleave', this.#drop.bind(this));
    this.clearButton.addEventListener('click', this.#clear.bind(this));
  }

  #dragNdrop(event) {
    if (!event.target.files.length) {
      this.#hideError();
      return;
    }
    Array.from(event.target.files).forEach((file) => {
      if (file.size > MAX_SIZE) {
        this.#showError();
        this.fileInput.setCustomValidity('File size exceeds 10MB');
        this.fileInput.reportValidity();
      }
      else {
        this.#hideError();
        this.fileInput.setCustomValidity('');
        this.fileInput.reportValidity();
      }
    });
    const fileName = URL.createObjectURL(event.target.files[0]);
    const previewImg = document.createElement('img');
    previewImg.setAttribute('src', fileName);
    this.preview.innerHTML = '';
    this.preview.appendChild(previewImg);
    this.editorHolder.style.display = 'block';
    this.uploadHolder.style.display = 'none';
  }

  #drag() {
    this.fileInput.parentNode.className = 'draging dragBox';
  }

  #drop() {
    this.fileInput.parentNode.className = 'dragBox';
  }

  #clear() {
    this.fileInput.value = '';
    this.fileInput.dispatchEvent(new Event('change', { bubbles: true })); // needed to trigger re-render of error messages
    this.preview.innerHTML = '';
    this.editorHolder.style.display = 'none';
    this.uploadHolder.style.display = 'block';
  }

  #showError() {
    if (this.errorEl) this.errorEl.style.display = 'block';
  }

  #hideError() {
    if (this.errorEl) this.errorEl.style.display = 'none';
  }

  destroy() {
    this.fileInput.removeEventListener('change', this.#dragNdrop);
    this.fileInput.removeEventListener('drop', this.#drop);
    this.fileInput.removeEventListener('dragover', this.#drag);
    this.fileInput.removeEventListener('dragleave', this.#drop);
    this.clearButton.removeEventListener('click', this.#clear);
  }
}

export default ImageSelector;
